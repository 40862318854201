var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aAnchor',{on:{"change":_vm.smoothScroll}},[_c('div',{staticClass:"title"},[_vm._v("Documentos")]),_vm._v(" "),_c('draggable',{on:{"end":_vm.onEndDragOrderDocuments},model:{value:(_vm.docsArr),callback:function ($$v) {_vm.docsArr=$$v},expression:"docsArr"}},_vm._l((_vm.docsArr),function(doc,index){return _c('div',{key:index},[_c('aAnchorLink',{attrs:{"href":`#doc-${doc.id}`},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"dotted-phrase"},[_c('a-checkbox',{staticClass:"mr-10",staticStyle:{"font-size":"13px","font-weight":"500","position":"relative"},on:{"change":_vm.onCheckShowDocument},model:{value:(doc.show),callback:function ($$v) {_vm.$set(doc, "show", $$v)},expression:"doc.show"}}),_vm._v(" "),(doc.id == 91)?_c('a-tooltip',{attrs:{"placement":"top","title":"Ver dados do cartão"}},[_c('a-button',{staticClass:"mr-5",attrs:{"type":"primary","shape":"circle","size":"small","icon":"eye"},on:{"click":_vm.viewCreditCardData}})],1):_vm._e(),_vm._v("\n              "+_vm._s(doc.id)+" - "+_vm._s(doc.name)+"\n            ")],1)]},proxy:true}],null,true)})],1)}),0)],1),_vm._v(" "),(_vm.editDocuments)?_c('aForm',{attrs:{"form":_vm.documentsForm},on:{"submit":_vm.submitDocumentsForm}},[_c('a-form-item',{staticClass:"mt-20 mb-10 contract-docs"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'contract_documents',
          {
            initialValue: _vm.selectedDocs,
            rules: [
              {
                required: true,
                message: 'Selecione os documentos que serão gerados.',
              },
            ],
          },
        ]),expression:"[\n          'contract_documents',\n          {\n            initialValue: selectedDocs,\n            rules: [\n              {\n                required: true,\n                message: 'Selecione os documentos que serão gerados.',\n              },\n            ],\n          },\n        ]"}],staticClass:"travel-input f12",attrs:{"show-search":"","placeholder":"Selecione os documentos...","mode":"multiple"}},_vm._l((_vm.documentsList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v("\n          #"+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n        ")])}),1)],1),_vm._v(" "),_c('div',{staticClass:"a-center"},[_c('a-button',{attrs:{"loading":_vm.loadingDocumentsForm,"type":"primary","ghost":"","html-type":"submit"}},[_vm._v("\n        Salvar documentos\n      ")])],1)],1):_vm._e(),_vm._v(" "),_c('aModal',{attrs:{"visible":_vm.showPasswordModal,"footer":false,"width":300},on:{"cancel":function($event){_vm.showPasswordModal = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('aIcon',{attrs:{"type":"lock"}}),_vm._v(" Verificação ")]},proxy:true}])},[_vm._v(" "),(_vm.showPasswordModal)?_c('LoginFormModal',{attrs:{"updateStorageData":false},on:{"verificationSuccessfully":_vm.verificationSuccessfully}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }